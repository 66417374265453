import { createContext, useRef } from "react";

// Context to store video players
export const VideoControlContext = createContext();

export const VideoControlProvider = ({ children }) => {
  const videoPlayersRef = useRef([]);

  const addPlayer = (player) => {
    videoPlayersRef.current.push(player);
  };

  const removePlayer = (player) => {
    videoPlayersRef.current = videoPlayersRef.current.filter(
      (p) => p !== player
    );
  };

  const playAll = () => {
    videoPlayersRef.current.forEach((player) => player.play());
  };

  const pauseAll = () => {
    videoPlayersRef.current.forEach((player) => player.pause());
  };

  const forwardAll = (seconds = 10) => {
    videoPlayersRef.current.forEach((player) =>
      player.currentTime(player.currentTime() + seconds)
    );
  };

  const backwardAll = (seconds = 10) => {
    videoPlayersRef.current.forEach((player) =>
      player.currentTime(player.currentTime() - seconds)
    );
  };

  return (
    <VideoControlContext.Provider
      value={{
        addPlayer,
        removePlayer,
        playAll,
        pauseAll,
        forwardAll,
        backwardAll,
      }}
    >
      {children}
    </VideoControlContext.Provider>
  );
};
