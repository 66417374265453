import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { HandleOnChangeInput } from "../../../../../../../../helpers/utils/formInput/HandleOnChangeInput";
import Spinner from "../../../../../../../Spinner";
import { SizedBox } from "../../../../../../../../helpers/utils/SizedBox";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GlobalUserProfileContext } from "../../../../../../../../App";
import { APIUtils } from "../../../../../../../../helpers/utils/api";
import APIEndpoints from "../../../../../../../../config/api/endpoints";
import { displayMessage } from "../../../../../../../../helpers/displayMessage";

const Wrapper = styled.div`
  width: 40vw;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    width: 95vw;
  }
  .fieldsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
    &::placeholder {
      color: ${(props) => (props.theme === "dark" ? "white" : "black")};
    }
    input {
      color: ${(props) =>
        props.theme === "dark" ? "white !important" : "black !important"};
      text-indent: 0.5rem !important;
      height: 30px !important;
    }

    .submit-btn-wrapper {
      align-self: center;

      .add-camera_btn {
        padding: 0.4rem 1.2rem;
      }
    }
  }
`;
export const SendAnalyticEventReportDocumentForm = ({ reportItem }) => {
  const [data, setData] = useState({});

  const { profile } = useContext(GlobalUserProfileContext);

  const [isSubmittingData, setIsSubmittingData] = useState(false);

  const { theme } = useSelector((state) => state.themeReducer);
  const callBackSendReport = useCallback(async () => {
    console.log(data);

    if (!data?.notification_email_address) {
      toast.error("Fields are required.");
      return;
    }
    setIsSubmittingData(true);

    await APIUtils.fetchItemData({
      endpoint: (profile
        ? APIEndpoints.instance.stream.analytic.global_notification.report.main
        : APIEndpoints.instance.stream.analytic.global_notification.report.guest
      ).create({
        id: reportItem.obj_id,
        email: data.notification_email_address,
      }),
      item: isSubmittingData,
      setItem: setIsSubmittingData,
      fetchWithSecretKey: !profile,
    });
    displayMessage(
      "Generating Report",
      "The report will be delivered to your provided email shortly."
    );
    setIsSubmittingData(false);
  }, [data]);

  return (
    <Wrapper theme={theme}>
      <div className='fieldsWrapper'>
        <input
          onChange={(e) =>
            HandleOnChangeInput(
              e,
              "notification_email_address",
              setData,
              data,
              "value"
            )
          }
          placeholder='email address'
          type='email'
          className='form-control form-control-custom'
          id='notification_email_address'
        />
        <SizedBox height={2} />
        <div className='submit-btn-wrapper'>
          {isSubmittingData ? (
            <Spinner size={1.5} />
          ) : (
            <button
              className={`orange-text add-camera_btn ${
                theme === "dark" && "add-camera_btn-dark"
              }`}
              onClick={() => callBackSendReport()}
            >
              SAVE
            </button>
          )}
        </div>
      </div>
    </Wrapper>
  );
};
