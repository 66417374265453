import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { LivestreamNotificationReportContext } from "../../Container";
import axiosInstance from "../../../../../../../../config/axios";
import displayError from "../../../../../../../../helpers/displayError";
import { HandleOnChangeInput } from "../../../../../../../../helpers/utils/formInput/HandleOnChangeInput";
import moment from "moment";
import Spinner from "../../../../../../../Spinner";
import { SizedBox } from "../../../../../../../../helpers/utils/SizedBox";
import CameraFilters from "../../filter";
import { useSelector } from "react-redux";
import { StreamAnalyticInstanceConfig } from "../../../../../../../../config/instance/stream/analytics";
import { StreamAnalyticInstancesAvailableTypes } from "../../../../../../../../config/enums/instance/stream/analytic";
import { toast } from "react-toastify";

const Wrapper = styled.div`
  width: 40vw;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    width: 95vw;
  }
  .fieldsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;

    label {
      color: ${(props) => (props.theme === "dark" ? "white" : "black")};
    }
    input {
      color: ${(props) =>
        props.theme === "dark" ? "white !important" : "black !important"};
      text-indent: 0.5rem !important;
      height: 30px !important;
    }

    .submit-btn-wrapper {
      align-self: center;

      .add-camera_btn {
        padding: 0.4rem 1.2rem;
      }
    }

    select {
      display: initial !important;
      wisdth: rem;
    }

    textarea {
      height: 10rem;
      border: 0.5px solid !important;
      padding: 0.5rem;
      border-radius: 5px;

      &:focus {
        border: 0.5px solid !important;
      }
    }
  }
  span {
    font-size: 0.5rem !important;
    color: ${(props) =>
      props.theme === "dark" ? "white!important" : "black!important"};
  }

  .react-select__multi-value__label {
    span {
      font-size: 0.9rem !important;
    }
  }
  .react-select__option {
    span {
      font-size: 0.9rem !important;
      text-overflow: "ellipsis";
    }
  }
  .react-select-container {
    span {
      display: none;
    }
  }
`;
export const GenerateAnalyticEventReportDocumentForm = () => {
  const [data, setData] = useState({});

  const [filterData, setFilterData] = useState("");
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const { setGenerateReportDocumentModalOpened } = useContext(
    LivestreamNotificationReportContext
  );
  const { theme } = useSelector((state) => state.themeReducer);
  const callBackSaveReport = useCallback(async () => {
    if (
      !filterData?.Site?.length >= 1 ||
      !filterData?.Features?.length >= 1 ||
      !data?.start_date ||
      !data?.end_date
    ) {
      toast.error("Fields are required.");
      return;
    }

    const params = { projects: [], type: [], streams: [] };

    if (filterData?.Features?.length >= 1) {
      params.type = filterData.Features.map((item) => item.label);
    }

    if (filterData?.Site?.length >= 1) {
      params.projects = filterData.Site.map((item) => item.label);
    }

    if (filterData?.Camera?.length >= 1) {
      params.streams = filterData.Camera.map((item) => item.label);
    }

    const requestData = {
      ...data,
      offset_from_utc_in_secs: moment().utcOffset(),
      ...params,
    };

    const endpoint =
      StreamAnalyticInstanceConfig.Definitions[
        StreamAnalyticInstancesAvailableTypes["GLOBAL_NOTIFICATION"]
      ].apiEndpointsEntryPoint.report.main.createNew();

    setIsSubmittingData(true);

    await (
      await axiosInstance()
    )
      .post(endpoint, requestData)
      .then((response) => {
        setTimeout(() => {
          setIsSubmittingData(false);
          setGenerateReportDocumentModalOpened(false);
          setTimeout(() => {
            window.location.reload();
          }, 0);
        }, 2000);
      })
      .catch((err) => {
        displayError(err, theme);
        setTimeout(() => {
          setIsSubmittingData(false);
        }, 2000);
      });
  }, [data, isSubmittingData, filterData]);

  return (
    <Wrapper theme={theme}>
      <div className='fieldsWrapper'>
        <CameraFilters setterCallback={setFilterData} />
        <SizedBox height={2} />
        <label htmlFor='remark-type'>Start Date</label>
        <input
          onChange={(e) =>
            HandleOnChangeInput(
              e,
              "start_date",
              setData,
              data,
              "value",
              (value) => moment(value).format("YYYY-MM-DD")
            )
          }
          type='date'
          className='form-control form-control-custom'
          required
          id='start_date'
        />
        <SizedBox height={2} />
        <label htmlFor='remark-type'>End Date</label>
        <input
          onChange={(e) =>
            HandleOnChangeInput(
              e,
              "end_date",
              setData,
              data,
              "value",
              (value) => moment(value).format("YYYY-MM-DD")
            )
          }
          type='date'
          className='form-control form-control-custom'
          id='end_date'
          required
        />

        <SizedBox height={2} />
        <div className='submit-btn-wrapper'>
          {isSubmittingData ? (
            <Spinner size={1.5} />
          ) : (
            <button
              className={`orange-text add-camera_btn ${
                theme === "dark" && "add-camera_btn-dark"
              }`}
              onClick={() => callBackSaveReport()}
            >
              SAVE
            </button>
          )}
        </div>
      </div>
    </Wrapper>
  );
};
