export class APIEndpointInstanceStreamAnalyticsMotionDetectorInstance {
  static getForStream(id) {
    return `/instance/stream/analytic/motion_detector/stream/${id}/instance/`;
  }

  static get(id) {
    // return `/instance/stream/analytic/line_intrusion_detector/instance/${id}/`
    return "";
  }

  static update(id) {
    // return `/instance/stream/analytic/line_intrusion_detector/instance/${id}/`
    return "";
  }

  static destroy(id) {
    // return `/instance/stream/analytic/line_intrusion_detector/instance/${id}/`
    return "";
  }
}
