import React, { useContext } from "react";
import { HandleOnChangeInput } from "../../../../helpers/utils/formInput/HandleOnChangeInput";
import moment from "moment";
import { GlobalHeatmapsContext } from "./Heatmaps";

export const InputDates = () => {
  const { filterData, setFilterData } = useContext(GlobalHeatmapsContext);

  return (
    <div className='fieldsWrapper'>
      <label htmlFor='remark-type'>From</label>
      <input
        onChange={(e) =>
          HandleOnChangeInput(
            e,
            "from_date",
            setFilterData,
            filterData,
            "value",
            (value) => moment(value).utc().format("YYYY-MM-DD HH:mm")
          )
        }
        type='datetime-local'
        className='form-control form-control-custom'
        required
        id='from_date'
      />

      <label htmlFor='remark-type'>To</label>
      <input
        onChange={(e) =>
          HandleOnChangeInput(
            e,
            "to_date",
            setFilterData,
            filterData,
            "value",
            (value) => moment(value).utc().format("YYYY-MM-DD HH:mm")
          )
        }
        type='datetime-local'
        className='form-control form-control-custom'
        id='to_date'
        required
      />
    </div>
  );
};
